/* Fonts */

@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?3nsrcx');
    src:  url('fonts/icomoon.eot?3nsrcx#iefix') format('embedded-opentype'),
        url('fonts/icomoon.ttf?3nsrcx') format('truetype'),
        url('fonts/icomoon.woff?3nsrcx') format('woff'),
        url('fonts/icomoon.svg?3nsrcx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
/* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

/* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-menu-vertical:before {
    content: "\e900";
}
.icon-tick:before {
    content: "\e5ca";
}
.icon-leader-board:before {
    content: "\e01d";
}
.icon-info:before {
    content: "\e88f";
}
.icon-select:before {
    content: "\e5d7";
}
.icon-pencil:before {
    content: "\e906";
}
.icon-user:before {
    content: "\e971";
}
.icon-admin:before {
    content: "\e976";
}
.icon-pie-chart:before {
  content: "\e99a";
}
.icon-trophy:before {
    content: "\e99e";
}
.icon-clipboard:before {
    content: "\e9b8";
}
.icon-reload:before {
    content: "\ea2e";
}
.icon-arrow-up:before {
  content: "\ea3a";
}


/* General */

* {
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 1.2rem;
    color: #666;
    -webkit-overflow-scrolling: touch;
}

html.no-scroll, html.no-scroll body {
    overflow-y: hidden;
}

h1, h2, h3, h4, h5, h6 {
    color: #444;
}

input[type="text"], input[type="tel"], input[type="password"] {
    height: 3.8rem;
    padding: .7rem 1rem;
    font-size: 1.4rem;
    border: 1px solid #aaa;
    outline: 0;
}

input[type="tel"]:focus, input[type="tel"]:focus, input[type="password"]:focus {
    border-color: #27d3ff;
}

a {
    display: inline-block;
    color: #27d3ff;
    text-decoration: none;
}

ul {
    padding-left: 2rem;
}

li {
    list-style: none;
    margin-bottom: .5rem;
}

.marked-list li {
    list-style: disc;
    line-height: 1.8rem;
}

.marked-list li ul {
    margin-top: .5rem;
}

.marked-list li ul li {
    position: relative;
    list-style: none;
}

.marked-list li ul li:before {
    position: absolute;
    display: inline-block;
    content: "-";
    top: 0;
    left: -1rem;
}

button.link {
    display: inline-block;
    padding: 0;
    border: none;
    background-color: transparent;
    color: #27d3ff;
    font-size: 1.4rem;
    text-align: left;
    cursor: pointer;
}

button.link:hover {
    text-decoration: underline;
}

p {
    line-height: 1.8rem;
}

hr {
    border-top: 1px solid #27d3ff;
    border-bottom: none;
}

.overlay {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    background-color: rgba(0, 0, 0, .8);
    z-index: 1000;
}


/* Helper Rules */

.hide {
    display: none !important;
}

.bold {
    font-weight: bold;
    color: #444;
}

.mt-20 {
    margin-top: 2rem;
}

.mb-5 {
    margin-bottom: .5rem;
}

.mb-10 {
    margin-bottom: 1rem;
}

.green {
    color: green !important;
}

.orange {
    color: orange !important;
}

.red {
    color: red !important;
}

.blue {
    color: #458fff !important;
}

.bg-green {
    background-color: green !important;
}

.bg-orange {
    background-color: orange !important;
}

.bg-red {
    background-color: red !important;
}

.bg-blue {
    background-color: #458fff !important;
}


/* Media Queries */

@media (min-width: 768px) {
    html.no-scroll, html.no-scroll body {
        overflow-y: auto;
    }

    body {
        font-size: 1.4rem;
    }

    p {
        line-height: 2rem;
    }

    input[type="text"], input[type="tel"], input[type="password"] {
        height: 4.4rem;
        font-size: 1.6rem;
    }

    .marked-list li {
        line-height: 2rem;
    }
}
