/* General */

/* Used when hiding things like profile pics while at work - START */
/* h2,
.profile-pic,
.tip .pic,
.game-account-totals .pic,
.podium .pic,
.podium .icon-trophy,
.loser .pic,
.stat-item .pic {
    display: none !important;
}
.icon-admin {
    top: 2rem !important;
}
div.bg-green,
div.bg-orange,
div.bg-red {
    background-color: #458fff !important;
}
.race-selector .circle.selected.bg-green::after,
.race-selector .circle.selected.bg-orange::after,
.race-selector .circle.selected.bg-red::after {
    border-top-color: #458fff !important;
}
.browser {
    background: none !important;
} */
/* Used when hiding things like profile pics while at work - END */

.sr-only {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	margin: -1px !important;
	padding: 0 !important;
	overflow: hidden !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
	border: 0 !important;
	white-space: nowrap !important;
}

.loading-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13rem;
    height: 1.4rem;
    margin-top: -8rem;
    margin-left: -6.5rem;
    text-align: center;
}

.loading-msg img {
    display: block;
    width: 10rem;
}

.loading-msg .icon {
    width: 13rem;
    height: 9rem;
    margin-bottom: 1rem;
    background: url('images/horse-sprite.jpg') 0 0;
    animation: run 800ms infinite steps(12);
}

.loading-msg .text {
    margin-bottom: 0.5rem;
    font-size: 1.8rem;
}

@keyframes run {
    from {
        background-position-x: 0;
    }

    to {
        background-position-x: -1560px;
    }
}

.timeout-msg {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 32rem;
    margin-top: -20.8rem;
    margin-left: -16rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.timeout-msg h2 {
    margin-bottom: 2rem;
}

.timeout-msg img {
    display: block;
    width: 30rem;
    max-width: 100%;
    margin-bottom: 2rem;
}

.timeout-msg p {
    font-size: 1.6rem;
    line-height: 2.2rem;
}

.app {
    position: relative;
    max-width: 100rem;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem 1rem 8rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.error {
    margin-top: 2rem;
    color: #f00c;
}

.btn {
    display: flex;
    width: 12rem;
    justify-content: center;
    line-height: 4rem;
    cursor: pointer;
    border: 1px solid #aaa;
    color: #444;
    font-size: 1.4rem;
    background-color: #eee;
    outline: 0;
}

.competition-selector {
    margin-top: 4rem;
}

.competition-selector ul {
    padding-left: 0;
}

.competition-selector .link {
    margin-bottom: 1rem;
}

.competition-selector .link.disabled {
    color: #ccc;
    pointer-events: none;
}

.competition-selector a:hover {
    text-decoration: underline;
}

.selector {
    position: relative;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.selector select {
    width: 100%;
    height: 3.8rem;
    padding: 0.8rem 2.6rem 0.8rem 0.8rem;
    overflow: hidden;
    font-family: 'Open Sans';
    font-size: 1.2rem;
    color: #666;
    border: 1px solid #aaa;
    background-color: #fff;
    border-radius: 0;
    outline: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.selector select::-ms-expand {
    display: none;
}

.selector select:disabled::-ms-expand {
    background: #aaa;
}

.selector span {
    position: absolute;
    display: block;
    top: 50%;
    right: 0.5rem;
    width: 2.2rem;
    height: 2.2rem;
    margin-top: -1.1rem;
    font-size: 2.2rem;
    cursor: pointer;
}



/* Header */

h2 {
    margin-top: 0.5rem;
    margin-bottom: 4rem;
    font-size: 1.6rem;
}

h2 img {
    width: 4rem;
    height: 4rem;
    vertical-align: middle;
}

.beta {
    display: none;
    padding: 0.1rem 0.3rem;
    margin-left: -0.5rem;
    font-size: 0.8rem;
    color: #fff;
    background-color: #e029e8;
    vertical-align: -7px;
}

.comp-name {
    position: absolute;
    top: 5rem;
    max-width: 21.5rem;
    text-transform: uppercase;
}

.overlay .btn {
    align-self: center;
}

.info-icon {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    font-size: 2rem;
    line-height: 2.8rem;
    color: #333;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
    outline: 0;
}

.profile-pic {
    position: absolute;
    top: 2rem;
    right: 5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.reload-btn {
    position: absolute;
    top: 8.8rem;
    right: 1.2rem;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem 0.6rem 0.2rem;
    border: 1px solid #ccc;
    border-radius: 100%;
    font-size: 1.6rem;
    color: #333;
    background-color: #eee;
    cursor: pointer;
    outline: 0;
}

.reload-btn::-moz-focus-inner {
    border: 0;
}

.reload-btn:active {
    background-color: #aaa;
}

.reload-btn.loading {
    animation: 1s linear infinite spinner;
    pointer-events: none;
}

.icon-reload {
    vertical-align: 1px;
}

@keyframes loading {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

.reload-btn.loading {
    animation: loading 1s linear infinite;
}

.icon-admin {
    margin-right: 1rem;
    padding: 0.5rem 0.6rem 0.7rem;
    border: 1px solid #ccc;
    border-radius: 100%;
    font-size: 1.6rem;
    line-height: 3rem !important;
    color: #333;
    background-color: #fff;
    cursor: pointer;
    outline: 0;
}

.info-icon.selected,
.icon-admin.selected {
    color: #27d3ff;
    border-color: #27d3ff;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
         transform: rotate(360deg);
    }
}



/* Top Menu */

.top-menu {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 5rem);
    padding: 2rem 3rem;
    background-color: #eee;
    border-left: 1px solid #aaa;
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 400ms ease-in-out;
}

.top-menu.open {
    transform: translateX(0);
    transition: transform 400ms ease-in-out;
}

.top-menu::before {
    position: absolute;
    display: block;
    content: ' ';
    top: 0;
    left: -1000%;
    bottom: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: opacity 400ms linear, width 0s linear 400ms;
}

.top-menu.open::before {
    width: 1000%;
    opacity: 1;
    transition: opacity 400ms linear;
}

.top-menu a {
    display: block;
    margin-bottom: 4rem;
    font-size: 1.4rem;
}

.menu-btn-container {
    position: absolute;
    top: 1.4rem;
    left: -5rem;
    padding: .5rem 1.3rem .3rem .6rem;
    background-color: #fff;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #fff;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    transition: background-color 400ms linear, border-color 400ms linear;
}

.top-menu.open .menu-btn-container {
    background-color: #eee;
    border-color: #aaa;
    border-right-color: #eee;
    transition: background-color 400ms linear, border-color 400ms linear;
}

.menu-btn-container .icon-menu-vertical {
    display: flex;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-radius: 100%;
    font-size: 2.4rem;
    text-align: center;
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 400ms linear;
}

.menu-btn-container .icon-menu-vertical::before {
    margin-top: -0.2rem;
}

.top-menu.open .menu-btn-container .icon-menu-vertical {
    opacity: 0;
    transform: rotate(90deg);
    transition: all 400ms linear;
}

.menu-btn-container .icon-arrow-up {
    position: absolute;
    top: 50%;
    left: 1.2rem;
    bottom: 1.2rem;
    width: 2rem;
    height: 2rem;
    margin-top: -1rem;
    opacity: 0;
    font-size: 2rem;
    cursor: pointer;
    transform: rotate(0deg);
    transition: all 400ms linear;
}

.top-menu.open .menu-btn-container .icon-arrow-up {
    opacity: 1;
    transform: rotate(90deg);
    transition: all 400ms linear;
}

.logout {
    margin-bottom: 2rem;
    background-color: #ccc;
}



/* Bottom Menu */

.bottom-menu {
    position: fixed;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 2px rgba(0, 0, 0, 0.24);
}

.bottom-menu a {
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: #ccc;
    text-decoration: none;
    text-align: center;
}

.bottom-menu a.selected {
    color: #27d3ff;
}

.bottom-menu .icon {
    margin-bottom: 0.7rem;
    font-size: 2.8rem;
}

.bottom-menu .icon-info,
.bottom-menu .icon-leader-board {
    margin-bottom: 0;
    font-size: 3.5rem;
}



/* Login */

label.label {
    display: block;
    margin-bottom: 0.4rem;
    transform: translate(1rem, 3rem);
    transition: transform 200ms ease-in-out;
}

label.label.focused {
    transform: translate(0, 0);
    transition: transform 200ms ease-in-out;
}

input.password {
    width: 100%;
    height: 3.7rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
    margin-bottom: 4rem;
}



/* Admin */

.adminRace {
    margin-bottom: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #27d3ff;
}

.adminRace:first-child {
    padding-top: 0;
    border-top: none;
}

.adminRace .placings-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.adminRace .placings {
    display: flex;
    margin-bottom: 2rem;
}

.adminRace .placings > div {
    display: flex;
    width: 33.3333%;
    margin-right: 1rem;
    align-items: center;
}

.adminRace .placings > div:last-child {
    margin-right: 0;
}

.adminRace label {
    display: inline-block;
    margin-right: 1rem;
}

.adminRace input[type='tel'] {
    width: 100%;
}

.save-btn {
    display: flex;
    width: 100%;
    height: 4.4rem;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 1.4rem;
    color: #fff;
    background-color: #666;
    outline: 0;
    cursor: pointer;
}

.save-btn:hover {
    background-color: #000;
}

.loader,
.loader::after {
  border-radius: 50%;
  width: 20rem;
  height: 20rem;
}

.loader {
  margin: 6rem auto;
  font-size: 1rem;
  position: relative;
  border-top: 1.1rem solid rgba(255, 255, 255, 0.2);
  border-right: 1.1rem solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1rem solid rgba(255, 255, 255, 0.2);
  border-left: 1.1rem solid #ffffff;
  transform: translateZ(0);
  animation: rotate 1.1s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.dead-heat-msg {
    position: relative;
    margin-bottom: 2rem;
    padding-left: 0.8rem;
}

.dead-heat-msg::before {
    position: absolute;
    content: "*";
    display: block;
    top: 0;
    left: 0;
}

.scratching-list {
    margin-top: 2rem;
}

.scratching-list .selections {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 1rem;
}

.scratching-list .selection {
    display: flex;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #fff;
    line-height: 3rem;
    background-color: #aaa;
    cursor: pointer;
}

.scratching-list .selection.scratched {
    background: repeating-linear-gradient(
        -45deg,
        transparent 46%,
        #ff0000 49%,
        #ff0000 52%,
        transparent 55%
    );
}

.adminRace .status-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
}

.adminRace .status-selector-btn {
    width: 100%;
}

.adminRace .status-selector-btn.selected {
    border-color: #813aff;
    color: #fff;
    background-color: #813aff;
    pointer-events: none;
}

.adminRace .status-selector-btn.disabled {
    border-color: #ddd;
    color: #aaa;
    background-color: #eee;
    pointer-events: none;
}



/* Information */

.browser-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 0.5rem;
}

.browser-list li {
    margin-bottom: 0.5rem;
}

.browser {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
    background: url('images/browser-icons.jpg') no-repeat;
    background-size: cover;
    vertical-align: -1rem;
}

.browser.chrome {
    background-position: 0 0;
}

.browser.firefox {
    background-position: -30px 0;
}

.browser.safari {
    background-position: -90px 0;
}

.browser.opera {
    background-position: -60px 0;
}

.demo-square {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    vertical-align: -0.1rem;
}

.icon-admin.demo {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.2rem 0.3rem 0.3rem;
    font-size: 1rem;
}

.icon-info.demo {
    margin-left: 0.5rem;
    font-size: 1.8rem;
    vertical-align: -0.3rem;
}



/* Tips */

.tip-examples {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

.raceList .tip-group {
    margin-bottom: 2rem;
}

.raceList .details {
    margin-bottom: 1rem;
}

.raceList .name {
    display: inline-block;
    max-width: 24rem;
    margin-top: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    vertical-align: bottom;
}

.raceList .selections {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 1rem;
}

.raceList .selection,
.tip-examples .selection {
    display: flex;
    width: 3rem;
    height: 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: #fff;
    line-height: 3rem;
    background-color: #aaa;
    cursor: pointer;
}

.tip-examples .selection {
    margin-left: 3rem;
    margin-right: 0.4rem;
    cursor: default;
}

.tip-examples .selection:first-child {
    margin-left: 0;
}

.raceList .selection.selected,
.tip-examples .selection.selected {
    background-color: #813aff;
}

.raceList .selection.scratched,
.tip-examples .selection.scratched {
    background: repeating-linear-gradient(-45deg, transparent 46%, #ff0000 49%, #ff0000 52%, transparent 55%);
}

.raceList .selection.selected.scratched,
.tip-examples .selection.selected.scratched {
    background: repeating-linear-gradient(-45deg, #813aff 46%, #813aff 49%, #fff 52%, #813aff 55%);
}

.raceList .saved-status {
    margin-left: 1.2rem;
    color: green;
    opacity: 1;
    animation: fadeInSavedStatus 1s;
}

.raceList .saved-status::before {
    content: "\e5ca";
    margin-right: 0.2rem;
    font-family: 'icomoon';
    font-size: 1.4rem;
    line-height: 1.2rem;
    vertical-align: -2px;
}

.disabled .bold,
.disabled .details {
    color: #ccc;
}

.raceList.disabled .selection,
.raceList.disabled .status {
    pointer-events: none;
    opacity: 0.5;
}

.raceList.disabled .saved-status {
    opacity: 0.4;
    animation: fadeInSavedStatusDisabled 1s;
}

@keyframes fadeInSavedStatus {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInSavedStatusDisabled {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.4;
    }
}



/* Results */

.race-selector {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.race-selector .circle {
    position: relative;
    display: flex;
    width: 2.8rem;
    height: 2.8rem;
    justify-content: center;
    align-items: center;
    margin-right: 0.2rem;
    border-radius: 100%;
    opacity: 0.8;
    color: #fff;
    cursor: pointer;
}

.race-selector .circle:last-child {
    margin-right: 0;
}

.race-selector .circle.selected::after {
    position: absolute;
    display: block;
    content: ' ';
    left: calc(50% - 1rem);
    bottom: -1.6rem;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid;
    border-bottom: 1rem solid transparent;
}

.race-selector .circle.selected.bg-green::after {
    border-top-color: green;
}

.race-selector .circle.selected.bg-orange::after {
    border-top-color: orange;
}

.race-selector .circle.selected.bg-red::after {
    border-top-color: red;
}

.race-selector .circle.selected.bg-blue::after {
    border-top-color: #458fff;
}

.race {
    display: grid;
    grid-template-columns: minmax(auto, 19rem) 1fr;
    grid-template-areas:
        'details      placings'
        'tips-heading tips-heading'
        'tips         tips';
    grid-gap: 2rem;
}

.race .details {
    grid-area: details;
}

.race .details .name {
    text-transform: uppercase;
}

.race .details .status {
    font-weight: 600;
}

.race .placings {
    grid-area: placings;
}

.race .placing {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
}

.race .placing .label {
    display: inline-block;
    width: 6rem;
}

.race .placing .label.gold:before,
.race .placing .label.silver:before,
.race .placing .label.bronze:before {
    display: inline-block;
    content: ' ';
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
    border-radius: 100%;
    vertical-align: -0.4rem;
}

.race .placing .label.gold:before {
    background-color: #eacb3c;
}

.race .placing .label.silver:before {
    background-color: #e6e8fa;
}

.race .placing .label.bronze:before {
    background-color: #8c7853;
}

.tips-heading {
    grid-area: tips-heading;
}

.tips {
    display: grid;
    grid-area: tips;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
}

.tips .no-races-msg {
    grid-column-start: 1;
    grid-column-end: 5;
}

.tip {
    padding: 1rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.tip .pic {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    border-radius: 100%;
    vertical-align: -1rem;
}

.tip .name {
    display: inline-block;
    width: calc(100% - 3.5rem);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}

.tip .name::after {
    display: block;
    content: ' ';
}

.tip .numbers {
    display: inline-block;
    width: 66.6666%;
    margin-top: 1rem;
}

.tip .numbers .group {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.tip .numbers .group:last-child {
    margin-bottom: 0;
}

.tip .group span {
    display: inline-block;
    height: 1.4rem;
}

.tip .group .question-mark {
    width: 1.8rem;
    height: 1.8rem;
    background-color: #e7e7e7;
    text-align: center;
}

.tip .numbers .label {
    width: 3.6rem;
    font-weight: bold;
}

.tip .correct {
    color: #27d3ff;
}

.tip .correct::after {
    display: inline-block;
    content: '\e5ca';
    margin-left: 0.2rem;
    font-family: 'icomoon';
    vertical-align: -0.1rem;
}

.score {
    display: inline-block;
    width: 4rem;
    margin-top: 0.6rem;
    color: #444;
    font-size: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    vertical-align: top;
}

.score .heading {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.score .perfect {
    position: relative;
    width: 4rem;
    margin: 0 auto;
    color: #eacb3c;
}

.score .perfect::before,
.score .perfect::after {
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -10%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
}

.score .perfect::before {
    display: none;
    top: -75%;
    background-image:
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, transparent 20%, #27d3ff 20%, transparent 30%),
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #27d3ff 15%, transparent 20%),
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, #27d3ff 20%, transparent 20%);
    background-size: 20% 20%, 40% 40%, 30% 30%, 40% 40%, 36% 36%, 20% 20%, 30% 30%, 20% 20%, 36% 36%;
}

.score .perfect::after {
    display: none;
    bottom: -75%;
    background-image:
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, #27d3ff 20%, transparent 20%),
        radial-gradient(circle, transparent 10%, #27d3ff 15%, transparent 20%),
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, #813aff 20%, transparent 20%),
        radial-gradient(circle, #27d3ff 20%, transparent 20%),
        radial-gradient(circle, #813aff 20%, transparent 20%);
    background-size: 30% 30%, 40% 40%, 36% 36%, 40% 40%, 30% 30%, 20% 20%, 40% 40%;
}

.score .perfect.animate::before {
      display: block;
      animation: topBubbles 3s ease-in-out 1s infinite;
}

.score .perfect.animate::after {
    display: block;
    animation: bottomBubbles 3s ease-in-out 1s infinite;
}

@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
    17% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }
    34% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    17% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }
    34% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}



/* Leaderboard */

.lb-heading {
    margin-top: 3rem;
    text-align: center;
}

.lb-heading.teal {
    color: #27d3ff;
}

.game-account-totals {
    display: flex;
    max-width: 36rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.game-account-totals .total {
    width: 33.3333%;
    text-align: center;
}

.game-account-totals img {
    width: 6rem;
    height: 6rem;
    margin-bottom: 0.5rem;
    border-radius: 100%;
}

.game-account-totals .name {
    margin-bottom: 0.5rem;
}

.podium {
    display: flex;
    justify-content: center;
    height: 29.5rem;
    margin-top: 2rem;
    text-align: center;
}

.podium .first,
.podium .second,
.podium .third {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 33.3333%;
    max-width: 12rem;
}

.podium .pic {
    display: block;
    width: 8rem;
    height: 8rem;
    margin-bottom: 0.5rem;
    border-radius: 100%;
}

.podium .name {
    width: 90%;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.podium .bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    background-color: #ddd;
}

.podium .first .bar {
    height: 19rem;
}

.podium .second .bar {
    height: 13rem;
}

.podium .third .bar {
    height: 8rem;
}

.podium .icon-trophy {
    font-size: 6rem;
    margin-bottom: 4rem;
    color: #eacb3c;
}

.podium .bar .number {
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: #444;
}

.podium .bar sup {
    font-size: 1.2rem;
    vertical-align: 0.6rem;
}

.podium .bar .points {
    margin-bottom: 1.8rem;
}

.loser {
    max-width: 30rem;
    margin: 0 auto 1rem;
}

.loser .number {
    display: inline-block;
    margin-left: 1.8rem;
    margin-right: 1rem;
    width: 3rem;
    vertical-align: -0.1rem;
}

.loser .number sup {
    font-size: 0.8rem;
}

.loser .points {
    display: inline-block;
    margin-right: 1rem;
    width: 6rem;
    vertical-align: middle;
}

.loser .pic {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
    border-radius: 100%;
    vertical-align: middle;
}

.loser .name {
    display: inline-block;
    width: 11.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}



/* Statistics */

.tab-btns {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.tab-btn {
    flex-basis: 50%;
}

.tab-btn.selected {
    color: #fff;
    background-color: #813aff;
    border-color: #813aff;
}

.tab-panel hr:first-child {
    display: none;
}

.stat-container {
    display: flex;
    padding-bottom: 2rem;
    overflow-x: auto;
}

.stat-item {
    width: 6rem;
    margin-right: 2rem;
    text-align: center;
}

.stat-item .number {
    margin-bottom: 0.5rem;
}

.stat-item .number sup {
    font-size: 0.8rem;
}

.stat-item .pic {
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
}

.stat-item .name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}



/* **** Media Queries **** */

@media (max-width: 319px) {
    .timeout-msg {
        margin-left: -14rem;
    }

    .profile-pic {
        display: none;
    }

    .tip-examples .selection {
        margin-left: 1rem;
    }

    .race-selector .circle {
        width: 2.4rem;
        height: 2.4rem;
    }

    .race-selector .circle.selected.bg-green::after {
        bottom: -1.4rem;
    }

    .race {
        grid-template-columns: minmax(auto, 16rem) 1fr;
    }

    .tip .numbers,
    .score {
        width: 100%;
    }

    .loser {
        max-width: 26rem;
    }

    .loser .number {
        margin-right: 0;
        margin-left: 0.5rem;
    }

    .loser .points {
        width: 5rem;
    }

    .loser .name {
        width: 10.2rem;
    }

    .podium {
        margin-top: -2rem;
    }

    .podium .pic {
        width: 6rem;
        height: 6rem;
    }
}

@media (min-width: 360px) {
    .timeout-msg {
        max-width: 36rem;
        margin-left: -18rem;
    }

    .timeout-msg img {
        width: 34rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    .comp-name {
        max-width: 25rem;
    }

    .profile-pic {
        right: 5.5rem;
    }

    .race-selector .circle {
        width: 3rem;
        height: 3rem;
        margin-right: 0.4rem;
    }

    .raceList .name {
        max-width: 28rem;
    }

    .race {
        grid-template-columns: minmax(auto, 22rem) 1fr;
    }
}

@media (min-width: 375px) {
    .comp-name {
        max-width: 27rem;
    }

    .race-selector .circle {
        margin-right: 0.6rem;
    }

    .raceList .name {
        max-width: 29rem;
    }

    .race {
        grid-template-columns: minmax(auto, 23rem) 1fr;
    }
}

@media (min-width: 384px) {
    .race-selector .circle {
        margin-right: 0.7rem;
    }
}

@media (min-width: 400px) {
    .timeout-msg {
        max-width: 39rem;
        margin-left: -19.5rem;
    }

    .timeout-msg img {
        width: 35rem;
    }

    .comp-name {
        max-width: 30rem;
    }

    .race-selector .circle {
        margin-right: 0.8rem;
    }

    .raceList .name {
        max-width: 32rem;
    }

    .race {
        grid-template-columns: minmax(auto, 26rem) 1fr;
    }
}

@media (min-width: 410px) {
    .icon-admin {
        top: 2rem;
        right: 9rem;
    }
    .race-selector .circle {
        margin-right: 1rem;
    }
    .raceList .name {
        max-width: 33rem;
    }
}

@media (min-width: 500px) {
    .comp-name {
        max-width: 45rem;
    }

    .selector, input.password {
        max-width: 39rem;
    }

    .tips {
        grid-template-columns: repeat(3, 1fr);
    }

    .raceList .selections,
    .scratching-list .selections {
        grid-template-columns: repeat(12, 1fr);
    }

    .race {
        grid-template-columns: minmax(auto, 30rem) 1fr;
    }

    .adminRace .status-selector {
        grid-template-columns: repeat(4, 1fr);
    }

    .adminRace .placings {
        display: inline-flex;
    }

    .save-btn {
        display: inline-flex;
        max-width: 10rem;
        margin-left: 2rem;
    }
}

@media (min-width: 660px) {
    .tips {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 768px) {
    .app {
        padding: 1rem 2rem 10rem;
    }

    h2 {
        font-size: 2.4rem;
    }

    h2 img {
        width: 5rem;
        height: 5rem;
    }

    .comp-name {
        top: 5.5rem;
        max-width: 60rem;
    }

    .top-menu {
        display: grid;
        top: auto;
        left: 0;
        bottom: auto;
        grid-template-columns: 1fr 1fr;
        width: auto;
        height: 23.4rem;
        border: 1px solid #aaa;
        border-top: none;
        transform: translateY(-24.4rem);
    }

    .top-menu.open {
        transform: translateY(-1.3rem);
    }

    .top-menu::before {
        display: none;
    }

    .top-menu a {
        margin-bottom: 3rem;
    }

    .top-menu .competition-selector {
        margin-top: 0;
    }

    .top-menu ul,
    .top-menu li:last-child,
    .top-menu li:last-child button {
        margin-bottom: 0;
    }

    .top-menu h3 {
        margin-top: 0;
    }

    .top-menu .column {
        display: flex;
        flex-direction: column;
    }

    .top-menu .competition-container {
        padding-left: 4rem;
        border-left: 1px solid #ccc;
    }

    .profile-pic {
        width: 4rem;
        height: 4rem;
    }

    .menu-btn-container {
        top: auto;
        left: auto;
        right: 1rem;
        bottom: -6.2rem;
        padding: 2.8rem .6rem .4rem;
        border-top: none;
        border-right-color: #fff;
        border-top-left-radius: 0;
        border-bottom-right-radius: 2rem;
    }

    .top-menu.open .menu-btn-container {
        border-right-color: #aaa;
    }

    .menu-btn-container .icon-arrow-up {
        top: auto;
        left: 50%;
        margin-left: -1rem;
        transform: rotate(-90deg);
    }

    .top-menu.open .menu-btn-container .icon-arrow-up {
        transform: rotate(0deg);
    }

    .btn-container {
        margin-top: auto;
    }

    .btn,
    .save-btn {
        font-size: 1.6rem;
    }

    .logout {
        display: inline-flex;
        margin-right: 20px;
        margin-bottom: auto;
    }

    .reload-btn {
        right: 1.8rem;
    }

    .adminRace .placings-container {
        flex-direction: row;
        max-width: 49rem;
    }

    .tip .numbers .label {
        width: 4.1rem;
    }

    .login-page p {
        margin-bottom: 4rem;
    }

    .selector,
    input.password {
        max-width: 49rem;
    }

    .raceList .saved-status::before {
        vertical-align: -1px;
    }

    .scratching-list .selection,
    .raceList .selection,
    .tip-examples .selection {
        width: 3.6rem;
        height: 3.6rem;
        line-height: 3.6rem;
    }

    .race-selector .circle {
        width: 4rem;
        height: 4rem;
    }

    .race-selector .circle.selected::after {
        bottom: -1.75rem;
    }

    .race {
        grid-template-columns: minmax(auto, 40rem) 1fr;
    }

    .selector select {
        height: 4.4rem;
        font-size: 1.4rem;
    }

    label.label {
        transform: translate(1rem, 3.5rem);
    }

    .score {
        margin-top: 0.8rem;
    }

    .score .heading {
        font-size: 1.4rem;
    }

    .game-account-totals img {
        width: 7rem;
        height: 7rem;
    }

    .podium {
        height: 31.5rem;
    }

    .podium .first,
    .podium .second,
    .podium .third {
        max-width: 14rem;
    }

    .podium .pic {
        width: 9rem;
        height: 9rem;
    }

    .loser {
        max-width: 42rem;
    }

    .loser .number {
        margin-left: 7.2rem;
    }

    .loser .pic {
        width: 6rem;
        height: 6rem;
    }

    .loser .name {
        width: 16rem;
    }
}

@media (min-width: 992px) {
    .bottom-menu {
        grid-template-columns: 1fr 1fr repeat(4, 25rem) 1fr 1fr;
    }

    .bottom-menu a:first-child {
        grid-column-start: 3;
    }

    .raceList .selections,
    .scratching-list .selections {
        grid-template-columns: repeat(24, 1fr);
    }
}

@media (min-width: 1170px) {
    .app {
        max-width: 120rem;
    }

    .comp-name {
        max-width: 100rem;
    }

    label.label {
        transform: translate(1rem, 3.5rem);
    }

    .scratching-list .selection,
    .raceList .selection,
    .tip-examples .selection {
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
    }

    .tips {
        grid-template-columns: repeat(5, 1fr);
    }
}
